import { useEffect, useState } from "react";
import Link from "next/link";
import Image from 'next/image';
import { CategoryItem } from "@/lib/constants";
import { useTopBannerStore } from "@/stores/useTopBannerStore";
import { useRouter } from "next/router";


const DesktopMenuItem = (props: CategoryItem) => {
  const { name, slug, subcategory, type } = props;
  const router = useRouter();
  const { topBanner, getTopBanner } = useTopBannerStore();
  const [topBannerData, setTopBannerData] = useState(getTopBanner());
  const [categorySlug, setCategorySlug] = useState<string>("");
  const [hoverCategory, setHoverCategory] = useState<Array<CategoryItem>>([]);

  useEffect(() => {
    setTopBannerData(getTopBanner());
  }, [topBanner, getTopBanner])

  const render_category_c = () => {
    return (
      <div className="grid grid-cols-5 px-10 overflow-x-hidden gap-x-12 gap-y-8">
        {hoverCategory.map((category_c, index) => (
          <div key={index} className="relative w-full">
            <Link href={`/product-category/${slug}/${categorySlug}/${category_c.slug}`}>
              {category_c.label_image !== null && category_c.label_image.length > 0 && (
                <Image
                  src={category_c.label_image}
                  alt={category_c.name + " label"}
                  width={36}
                  height={18}
                  quality={100}
                  className="absolute top-0 left-0"
                />
              )}
              {category_c.image_icon !== null && (
                <div className="flex justify-center items-center h-[120px] w-[120px]">
                  <Image
                    src={category_c.image_icon}
                    alt={category_c.name}
                    width={120}
                    height={120}
                    quality={100}
                  />
                </div>
              )}
              <p className={`${category_c.bundle_category ? "text-review font-bold" : "text-black"} text-xs font-poppins mt-4 text-center`}>{category_c.name}</p>
            </Link>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="flex items-center justify-center py-[18px] hoverable font-poppins">
      {type === "A" && (
        <>
          <Link href={`/product-category/${slug}`}>
            <p
              className="text-xs font-medium text-black hover:text-mooimom"
              onMouseEnter={() => {setCategorySlug(subcategory[0].slug); setHoverCategory(subcategory[0].subcategory);}}
            >
              {name.replace("Kebutuhan ", "")}
            </p>
          </Link>
          <div className={`shadow-xl ${router.pathname === "/" ? "top-text-mega-menu" : topBannerData.data !== undefined && topBannerData.data.length > 0 && topBannerData.data[0].name === null && topBannerData.data[0].desktop_image === null && topBannerData.data[0].mobile_image === null ? "mega-menu" : "top-banner-mega-menu"}`}>
            <div className="container w-full mx-auto">
              <hr className="bg-menuHrBg" />
              <p className="my-4 text-base font-semibold font-poppins text-saleText">Sale</p>
              <div className="grid items-start grid-cols-3 mb-4 gap-x-16">
                {props?.promotions.map((promotion, index) => (
                  <Link key={index} href={`/product-category/all-promo?package=${promotion.id}`}>
                    <div className={`w-full flex justify-between items-center space-x-4`}>
                      <p className="text-xs font-medium text-black font-poppins">{promotion.name}</p>
                      <Image
                        src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/new-promo.png"}
                        alt="new promo icon"
                        quality={100}
                        width={100}
                        height={40}
                        className="w-8"
                      />
                    </div>
                  </Link>
                ))}
              </div>
              <hr className="bg-menuHrBg" />
              <div className="grid grid-cols-4 gap-6 py-6 mt-2 mb-4" style={{ height: "65vh" }}>
                <div className="col-span-1 overflow-y-auto">
                  {subcategory.filter(category_b => category_b.type === "B").map((category_b, index) => (
                    <Link key={index} href={`/product-category/${slug}/${category_b.slug}`}>
                      <div
                        className="flex items-center justify-between px-4 py-2 mt-4 space-x-4 text-xs font-medium text-black hover:text-white hover:bg-mooimom"
                        onMouseEnter={() => {setCategorySlug(category_b.slug); setHoverCategory(category_b.subcategory)}}
                      >
                        <p>{category_b.name}</p>
                        <Image
                          src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/arrow_right.png"}
                          alt="arrow icon"
                          width={14}
                          height={14}
                          quality={100}
                        />
                      </div>
                    </Link>
                  ))}
                </div>
                <div className="col-span-3 overflow-y-auto border-l border-solid border-attributeBorder">
                  {render_category_c()}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {type === "Special" && (
        <>
          {name === "What's On" ? (
            // <p className="text-sm font-medium text-black cursor-pointer hover:text-mooimom">
            //   {name}
            // </p>
            <></>
          ) : (
            <Link href={slug === "promotions" ? `/${slug}` :`/product-category/${slug}`}>
              <p className="text-xs font-medium text-black hover:text-mooimom">
                {name}
              </p>
            </Link>
          )}
        </>
      )}
      {type === "BRAND" && (
        <>
          <p className="text-xs font-medium text-black cursor-pointer hover:text-mooimom">
            {name}
          </p>
          <div className={`shadow-xl ${router.pathname === "/" ? "top-text-mega-menu" : topBannerData.data !== undefined && topBannerData.data.length > 0 && topBannerData.data[0].name === null && topBannerData.data[0].desktop_image === null && topBannerData.data[0].mobile_image === null ? "mega-menu" : "top-banner-mega-menu"}`}>
            <div className="container w-full mx-auto">
              <hr className="bg-menuHrBg" />
              <div className="grid grid-cols-3 py-4 gap-x-16" style={{ maxHeight: '80vh' }}>
                <div className="col-span-2 py-4" style={{ maxHeight: '75vh' }}>
                  <div className="text-base font-bold text-black">Featured Brands</div>
                  <div className="grid grid-cols-3 mt-8 gap-x-4 gap-y-8">
                    {props.featured_brands?.map((brand, index) => (
                      <Link key={index} href={`/product-category/brand?brand=${brand.name}`}>
                        <Image
                          key={index}
                          src={brand.image}
                          alt={brand.name}
                          width={240}
                          height={103}
                          quality={100}
                          className="rounded-lg"
                        />
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="col-span-1 px-8 py-4 overflow-y-auto border rounded-lg border-brandBorder border-opacity-40" style={{ maxHeight: '75vh' }}>
                  <div className="text-base font-bold text-black">All Brands</div>
                  {props.brands?.map((group, index) => (
                    <div key={index} className="flex my-4 space-x-8">
                      <p className="w-4 text-sm font-bold text-black font-poppins">{group.letters}</p>
                      <div className="w-full">
                        {group.brands.map((brand, index2) => {
                          if (index2 < group.brands.length - 1 || (props.brands !== undefined && index === props.brands.length - 1)) {
                            return (
                              <Link key={index2} href={`/product-category/brand?brand=${brand.name}`}>
                                <p className="mb-4 text-sm font-medium text-black font-poppins">{brand.name}</p>
                              </Link>
                            )
                          } else {
                            return (
                              <div key={index2}>
                                <Link href={`/product-category/brand?brand=${brand.name}`}>
                                  <p className="text-sm font-medium text-black font-poppins">{brand.name}</p>
                                </Link>
                                <hr className="mt-4 bg-reviewBg" />
                              </div>
                            )
                          }
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default DesktopMenuItem
