import Link from "next/link";
import Image from 'next/image';
import { CategoryItem } from "@/lib/constants";
import { useEffect, useState } from "react";
import SecondMobileMenu from "./SecondMobileMenu";
import { User } from '@/stores/initialState';
import { NextRouter } from "next/router";


interface CategoryListItemProps {
  categories: Array<CategoryItem>;
  isMenuOpen: boolean;
  userData: User;
  router: NextRouter;
}

const MobileMenuItem = (props: CategoryListItemProps) => {
  const { categories, isMenuOpen, userData, router } = props;
  const [showMenu, setShowMenu] = useState<number>(0)
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [category, setCategory] = useState<CategoryItem | null>(null);

  const handleShowMenu = (id: number) => {
    if (id === 0) {
      setShowMenu(id);
      setIsOpen(!isOpen);
    } else {
      const cc = categories.find(category => category.id === id);

      if (cc !== undefined && cc !== null) {
        setCategory(cc);
        setIsSubMenuOpen(true);
      }
    }
  }

  useEffect(() => {
    if (!isMenuOpen) {
      setIsOpen(false);
      setIsSubMenuOpen(false);
    }
  }, [isMenuOpen])

  const specialCategories = categories.filter(category => category.type === "Special");
  const mainCategories = categories.filter(category => category.type === "A");
  const brandCategories = categories.filter(category => category.type === "BRAND");

  const render_special_categories = () => {
    return specialCategories.map((category, index) => {
      if (index === 0) {
        return (
          <div key={index} className="mx-4 text-lg font-bold">{category.name}</div>
        )
      } else if (index === specialCategories.length - 1) {
        return (
          <div key={index}>
            <div className="mx-6 py-3 text-sm mt-3">
              <Link href={category.slug === "promotions" ? `/${category.slug}` :`/product-category/${category.slug}`}>
                {category.name}
              </Link>
            </div>
            {/* <div className="mx-2 my-6 text-sm">Event</div> */}
            <hr className="my-6 bg-reviewBg" />
          </div>
        )
      } else {
        return (
          <div key={index} className={`py-3 text-sm ${index === 1 ? "mt-6 font-bold bg-menuHighlightBg bg-opacity-60 text-menuHighlight px-6" : "mx-6 mt-3"}`}>
            <Link href={category.slug === "promotions" ? `/${category.slug}` :`/product-category/${category.slug}`}>
              {category.name}
            </Link>
          </div>
        )
      }
    })
  }

  const render_custom_categories = () => {
    return (
      <>
        <div>
          <p className="text-lg font-bold">Brands</p>
          {brandCategories.length > 0 && brandCategories[0].featured_brands.length > 0 && (
            <>
              {brandCategories[0].featured_brands.map((brand, index) => (
                <div key={index} className={index === 0 ? "mt-6" : "mt-8"}>
                  <Link href={`/product-category/brand?brand=${brand.name}`}>
                    <p className="text-sm font-normal font-poppins px-2">{brand.name}</p>
                  </Link>
                </div>
              ))}
              <div className="mt-8"
                onClick={() => handleShowMenu(brandCategories.length > 0 ? brandCategories[0].id : 0)}
              >
                <p className="text-sm font-normal font-poppins px-2">All Brands</p>
              </div>
            </>
          )}
        </div>
        <hr className="my-6 bg-reviewBg" />
        <div className="text-lg font-bold">
          <Link href={userData.token === "" ? "/login?redirect=https://affiliate.mooimom.id" : "https://affiliate.mooimom.id?uid=" + userData.token} target="_blank">
            Program Afiliasi
          </Link>
        </div>
        <hr className="my-6 bg-reviewBg" />
        <div className="text-lg font-bold">
          <Link href="/mamapedia-homepage">Mamapedia</Link>
        </div>
        <hr className="my-6 bg-reviewBg" />
        <div className="text-lg font-bold">Membership</div>
        <div className="mx-2 my-6 text-sm">
          <Link href="/rewards">MOOIMOM Rewards</Link>
        </div>
        <div className="mx-2 my-6 text-sm">
          <Link href="/user-warranty-info">Garansi Produk</Link>
        </div>
      </>
    )
  }

  return (
    <div className="w-full overflow-y-auto mega-menu-height font-poppins text-mobileMenu">
      {specialCategories.length > 0 && render_special_categories()}
      <div className="container">
        {mainCategories.length > 0 && (
          <>
            <div
              className="flex items-center justify-between"
              onClick={() => handleShowMenu(0)}
            >
              <p className="text-lg font-bold">Kategori</p>
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/arrow_right.png"}
                alt="arrow icon"
                width={14}
                height={14}
                quality={100}
                className={`cursor-pointer transition-transform ease-in-out duration-300 ${isOpen ? "rotate-90" : "rotate-0"}`}
              />
            </div>
            {mainCategories.map((category, index) => (
              <div key={index} className={`mx-2 my-6 grid grid-cols-3 gap-y-7 gap-x-5`}>
                {category.subcategory
                .flatMap(item => item.subcategory)
                .filter(sub => sub.type === "C")
                .slice(0, isOpen ? undefined : 3)
                .map((subItem, subIndex) => (
                  <Link key={subIndex} href={`/product-category/${category?.slug}/${category.subcategory.find(cat => cat.subcategory.includes(subItem))?.slug}/${subItem.slug}`}>
                    <div key={subIndex} className="w-full">
                      {subItem.image_icon !== null && (
                        <div className="flex justify-center">
                          <Image
                            src={subItem.image_icon}
                            alt={subItem.name}
                            width={56}
                            height={56}
                            quality={100}
                          />
                        </div>
                      )}
                      <p className={`${subItem.bundle_category ? "text-review font-bold" : "text-black"} text-xs font-poppins mt-2 text-center`}>{subItem.name}</p>
                    </div>
                  </Link>
                ))}
              </div>
            ))}
            <hr className="my-6 bg-reviewBg" />
          </>
        )}
        {render_custom_categories()}
        <SecondMobileMenu isOpen={isSubMenuOpen} setIsOpen={setIsSubMenuOpen} category={category} type={category?.type || "A"} />
      </div>
    </div>
  )
}

export default MobileMenuItem
